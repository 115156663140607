var countdown = document.querySelector('[data-countdown]');
if (countdown !== null) {
	var countDownDate = new Date(countdown.getAttribute('data-countdown'));
	var x = setInterval(function () {
		var now = new Date().getTime();

		// Find the distance between now and the count down date
		var distance = countDownDate - now;

		if (distance < 0) {
			distance = 0;
		}

		// Time calculations for days, hours, minutes and seconds
		var d = Math.floor(distance / (1000 * 60 * 60 * 24));
		var h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		var s = Math.floor((distance % (1000 * 60)) / 1000);

		var text = '';

		if (d > 0) {
			text += d + ' <small>jours</small> ';
		}

		text += ((h < 10) ? '0' + h : h) + ':' + ((m < 10) ? '0' + m : m) + ':' + ((s < 10) ? '0' + s : s);

		countdown.innerHTML = text;

		// If the count down is finished, stop the interval
		if (distance <= 0) {
			clearInterval(x);
		}
	}, 1000);
}
