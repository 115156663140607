document.addEventListener("DOMContentLoaded", function () {
    let readerQuantity = document.querySelector("#createCustomerReservation_cashlessAccount_readerQuantity");
    const readerQuantityAdmin = document.querySelector("#reservation_cashlessAccount_readerQuantity");
    if (readerQuantity || readerQuantityAdmin) {    
        if (readerQuantityAdmin) {
            readerQuantity = readerQuantityAdmin;
        }    
        readerQuantity.addEventListener("input", changeTotalValue);

        // Fonction appelée lors de la modification de la quantité
        function changeTotalValue(event) {
            const qtyElement = event.target;
            const totalElement = qtyElement.closest(".row").querySelector(".line-total-qty");

            const qty = parseFloat(qtyElement.value) || 0;

            changeReader(totalElement, qty);
        }

        function changeReader(totalElement, qty) {
            let packQty0 = 0;
            let packQty50 = 0;
            let packQty100 = 0;

            if (qty > 0) {
                // Toujours au moins 1 unité à 0 CHF si qty >= 1
                packQty0 = 1;

                if (qty > 1) {
                    // Calculer le nombre d'unités à 50 CHF (max 9)
                    packQty50 = Math.min(qty - 1, 9);
                }

                if (qty > 10) {
                    // Calculer le nombre d'unités à 100 CHF
                    packQty100 = qty - 1 - packQty50;
                }
            }

            // Calculer le total en fonction des différentes catégories
            const total = packQty0 * 0 + packQty50 * 50 + packQty100 * 100;

            if (total === 0) {
                totalElement.innerHTML = "= 0 pièce / 0 CHF (total)";
            } else {
                totalElement.innerHTML = `= ${qty} pièce${qty > 1 ? "s" : ""} / ${total} CHF (total)`;
            }
        }

        // Initialisation au chargement de la page
        const event = new Event("input");
        readerQuantity.dispatchEvent(event);
    }
});
